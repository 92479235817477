import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";

import axios from "axios";

// import Swal from "sweetalert2";
// Vue.use(Swal);

// const APP_ENV = "local";
const APP_ENV = "prod";
// const APP_ENV = "staging";

switch (APP_ENV) {
  case "local":
    axios.defaults.baseURL = "http://127.0.0.1:8000/api";

    break;
  case "staging":
    axios.defaults.baseURL = "https://api.hnc.co.ke/api";
    // console.log = function() {};

    break;
  case "prod":
    axios.defaults.baseURL = "https://api.hnc.co.ke/api";
    console.log = function() {};

    break;

  default:
    axios.defaults.baseURL = "https://api.hnc.co.ke/api";
    console.log = function() {};
    break;
}

const AUTH_TOKEN = localStorage.getItem("token");

// axios.defaults.baseURL = "http://127.0.0.1:8003/api";
// axios.defaults.baseURL = "https://api.hnc.co.ke/api";
// axios.defaults.headers.common["Authorization"] = "Bearer " + AUTH_TOKEN;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";

// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
// axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";
// axios.defaults.withCredentials = true;
axios.defaults.headers.post["Content-Type"] = "application/json";

// Vue.prototype.$http = Axios;
// Vue.use(axios);

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

// console.log = function() {};

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
