import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

// Views
const Test = () => import("@/views/pages/Test");

const Dashboard = () => import("@/views/Dashboard");

const FilesActive = () => import("@/views/pages/FilesActive");
const FilesDormant = () => import("@/views/pages/FilesDormant");
const FilesSendToClient = () => import("@/views/pages/FilesSendToClient");
const FilesClosed = () => import("@/views/pages/FilesClosed");
const FilesNew = () => import("@/views/pages/FilesNew");

const FilesSearch = () => import("@/views/pages/FilesSearch");

const BringupsToday = () => import("@/views/pages/BringupsToday");
const BringupsWeek = () => import("@/views/pages/BringupsWeek");
const BringupsMonth = () => import("@/views/pages/BringupsMonth");
const BringupsQuarter = () => import("@/views/pages/BringupsQuarter");
const BringupsOverdue = () => import("@/views/pages/BringupsOverdue");

const CaseDetails = () => import("@/views/pages/Case");

// Views - Pages
const Page404 = () => import("@/views/pages/Page404");
const Page500 = () => import("@/views/pages/Page500");
const Login = () => import("@/views/pages/Login");
const Register = () => import("@/views/pages/Register");
const ResetPassword = () => import("@/views/pages/ResetPassword");
const ForgotPassword = () => import("@/views/pages/ForgotPassword");
const ResetEmail = () => import("@/views/pages/CheckMail");

const Profile = () => import("@/views/pages/Profile");

// Users
const Users = () => import("@/views/pages/Users");

Vue.use(Router);

export default new Router({
  // mode: "hash",// https://router.vuejs.org/api/#mode
  mode: "history",
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Home",
      component: TheContainer,
      beforeEnter: (to, from, next) => {
        var user = JSON.parse(localStorage.getItem("user"));
        var token = localStorage.getItem("token");
        var login_time = parseInt(localStorage.getItem("login_time"));

        const time_now = Math.floor(Date.now() / 1000);
        const days3ago = 3 * 24 * 60 * 60;

        //logout if login_time is not set or login_time > 3 days ago
        if (
          !user ||
          !login_time ||
          !token ||
          time_now - login_time > days3ago
        ) {
          return next("/a/login");
        } else {
          next();
        }
      },
      children: [
        {
          path: "/test",
          name: "Test",
          component: Test,
        },
        {
          path: "dashboard",
          name: "Dashboard",
          component: Dashboard,
        },
        {
          path: "profile",
          name: "Profile",
          component: Profile,
        },
        // {
        //   path: "/files/:section",
        //   name: "Active Files",
        //   component: FilesActive,
        // },
        {
          path: "/files/active",
          name: "Active Files",
          component: FilesActive,
        },
        {
          path: "/files/dormant",
          name: "Dormant Files",
          component: FilesDormant,
        },
        {
          path: "/files/sendtoclient",
          name: "Send To Client Files",
          component: FilesSendToClient,
        },
        {
          path: "/files/closed",
          name: "Closed Files",
          component: FilesClosed,
        },
        {
          path: "/files/new",
          name: "New Case File",
          component: FilesNew,
        },
        {
          path: "/files/search/:term",
          name: "Search",
          component: FilesSearch,
        },
        {
          path: "/bringups/overdue",
          name: "Bringups: Overdue",
          component: BringupsOverdue,
        },
        {
          path: "/bringups/today",
          name: "Bringups: Today",
          component: BringupsToday,
        },
        {
          path: "/bringups/week",
          name: "Bringups: This Week",
          component: BringupsWeek,
        },
        {
          path: "/bringups/month",
          name: "Bringups: This Month",
          component: BringupsMonth,
        },
        {
          path: "/bringups/quarter",
          name: "Bringups: This Quarter",
          component: BringupsQuarter,
        },
        {
          path: "/case/details/:id",
          name: "Case",
          component: CaseDetails,
        },
        {
          path: "/users",
          name: "Users",
          component: Users,
        },
      ],
    },
    {
      path: "/a",
      redirect: "/a/login",
      name: "Auth",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "login",
          name: "Login",
          component: Login,
        },
        {
          path: "register",
          name: "Register",
          component: Register,
        },
        {
          path: "reset/:token",
          name: "Reset",
          component: ResetPassword,
        },
        {
          path: "forgot",
          name: "Forgot",
          component: ForgotPassword,
        },
        {
          path: "check/email",
          name: "Reset Email",
          component: ResetEmail,
        },
      ],
    },

    {
      path: "*",
      redirect: "/pages/404",
      name: "Pages",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      children: [
        {
          path: "404",
          name: "Page404",
          component: Page404,
        },
        {
          path: "500",
          name: "Page500",
          component: Page500,
        },
      ],
    },
  ];
}
